<template>
  <div class="section">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input label="下单人姓名" clearable v-model="searchParam.userName" />
        <v-input
          label="下单人联系方式"
          clearable
          v-model="searchParam.mobileNum"
        />
        <v-select
          clearable
          :options="statusOps"
          v-model="searchParam.status"
          label="订单状态"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getReceiveOrderListURL } from "./api.js";
import { statusMap, setStatusOps } from "./map.js";

export default {
  data() {
    return {
      statusOps: setStatusOps(),
      searchParam: {
        userName: "",
        mobileNum: "",
        status: undefined,
      },
      tableUrl: getReceiveOrderListURL,
      headers: [
        {
          prop: "userName",
          label: "下单人姓名",
        },
        // {
        //   prop: "id",
        //   label: "商品编号",
        // },
        {
          prop: "userMobilePhone",
          label: "下单人联系方式",
        },
        {
          prop: "insertTime",
          label: "下单时间",
        },
        {
          prop: "price",
          label: "支付金额（元）",
        },
        {
          prop: "goodsName",
          label: "商品名称",
        },
        {
          prop: "title",
          label: "商家名称",
        },
        {
          prop: "shopMobilePhone",
          label: "商家联系方式",
        },
        {
          prop: "receiveTime",
          label: "收货时间",
        },
        {
          prop: "status",
          label: "订单状态",
          formatter(row, prop) {
            return statusMap[row[prop]];
          },
        },
      ],
    };
  },
  computed: {
    ...mapState("app", ["userInfo"]),
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
}
</style>
